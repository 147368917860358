<template>
  <Details
    ref="details"
    title="Customer"
    resource="customers"
    :base-path="basePath"
    routeIdParam="customerId"
    :redirect-saved="false"
    title-template="{name} company details"
    breadcrumb-template="{name} company details"
  >
    <template v-slot:default="detailsProps">
      <DetailsBar class="relative mb-10">
        <div class="form-section-title" :style="{ paddingTop: 0 }">main information</div>
        <div class="form-row form-narrow">
          <div class="form-col">
            <label class="label">name</label>
            <div class="text-sm">{{ detailsProps.record.name }}</div>
          </div>
          <div class="form-col">
            <label class="label">HQ address</label>
            <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'address.addressLine1') }}</div>
          </div>
          <div class="form-col">
            <label class="label">city</label>
            <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'address.city') }}</div>
          </div>
          <div class="form-col">
            <label class="label">state</label>
            <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'address.state') }}</div>
          </div>
          <div class="form-col">
            <label class="label">zip</label>
            <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'address.zip') }}</div>
          </div>
        </div>
          <div class="form-row" :style="{ marginBottom: 0 }">
              <div class="form-col">
                  <label class="label">customer id</label>
                  <div class="inline-flex">
                      <div class="text-sm mr-2">{{ customerId }}</div>
                      <clipboard-button
                          class="focus:outline-none copy-id-btn"
                          :value="customerId"
                      ></clipboard-button>
                  </div>
              </div>
          </div>
        <div v-if="!detailsProps.record.deleted" class="absolute top-3 right-3">
          <button @click="editCustomerDetails">
            <icon name="pencil" class="edit-icon"/>
          </button>
        </div>
      </DetailsBar>

      <div class="form-section-title">licensing options</div>
      <div class="text-sm font-600 text-black mb-4">applications your company has access to</div>
      <List
        title="licensing options"
        resource=""
        :data="detailsProps.record.licenses || []"
        :fields="fields"
        track-by="bundleId"
      />
    </template>
  </Details>
</template>

<script>
  import {get} from 'lodash-es';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import Icon from '@/components/ui/Icon';
  import Details from '@/components/auth/details/Details';
  import DetailsBar from '@/components/auth/details/DetailsBar';
  import List from '@/components/auth/list/List';
  import AdvancedOptionsField from '@/components/auth/list/fields/AdvancedOptionsField';
  import ClipboardButton from "@/components/auth/list/ClipboardButton";

export default {
  name: 'CustomerDetails',
  mixins: [ModalNavigation],
  components: {
    Icon,
      ClipboardButton,
    Details,
    DetailsBar,
    List,
  },
  computed: {
    basePath: function () {
      return this.$route.path;
    },
    customerId() {
      return this.$route.params.customerId;
    },
    fields() {
      return [
        {
          name: 'bundleName',
          title: 'applications list',
        },
        {
          name: 'numberOfCommunities',
          title: 'number of communities',
        },
        {
          name: AdvancedOptionsField,
          title: 'advanced options',
          switch: {
            defaultOptions: ({ bundleId }) => this.defaultOptions[bundleId] || {},
          },
        },
      ];
    },
  },
  data() {
    return {
      defaultOptions: {},
    }
  },
  methods: {
    getNestedInfo(data, field) {
      return get(data, field);
    },
    editCustomerDetails() {
      this.$router.replace({
        name: 'customers.edit',
        params: this.$route.params,
      });
    },
  },
  mounted() {
    this.$authDataProvider.getList('bundles', {
      page: 0,
      size: 999,
      nonDeleted: true,
    }).then(({content}) => {
      this.defaultOptions = content.reduce((acc, { id, apps, type }) => ({
        ...acc,
        [id]: {
          apps: apps.map(({ appId, appName, attributes }) => ({ appId, appName, attributes })),
          type,
        },
      }), {});
    });
  },
};
</script>

<style scoped>

</style>
