<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-advoptions"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-advoptions">
    <div v-if="hasOptions">
      <div class="text-sm">
        <div v-for="(item, index) in lines" :key="index" >
          <div v-show="isOpen || index < maxLines">
            <span v-if="item.title" class="font-700" :class="{'mt-2': index > 0}">{{item.title}}</span>
            <span><span class="mr-1">-</span>{{item.text}}</span>
          </div>
        </div>
      </div>
      <button v-if="collapsible" type="button" class="text-sm flex items-center text-blue-500 font-500 mt-4" @click="toggleList">
        <span>{{isOpen ? 'less' : 'more'}}</span>
        <icon class="w-4 h-4" :class="{'transform rotate-180': isOpen}" name="chevronDown"/>
      </button>
    </div>
    <div v-else></div>
  </td>
</template>

<script>

import VuetableFieldMixin from "vue3-vuetable/src/components/VuetableFieldMixin";
import Icon from "@/components/ui/Icon";
import {BUNDLE_TYPES} from "@/components/auth/constants";

export default {
  name: "AdvancedOptionsField",
  mixins: [VuetableFieldMixin],
  components: { Icon },
  data: () => {
    return {
      isOpen: true,
    }
  },
  computed: {
    collapsible() {
      return this.lines.length > this.maxLines;
    },
    defaultOptions() {
      const defaultOptions = this.rowField.switch?.defaultOptions;
      return typeof defaultOptions === 'function' ? defaultOptions(this.rowData) : {};
    },
    options() {
      const source = this.rowField.switch?.source || 'options';
      return this.rowData[source] || [];
    },
    hasOptions() {
      return this.lines.length > 0;
    },
    maxLines() {
      return this.rowField.switch?.maxLines || 3;
    },
    lines() {
      const { apps = [], type } = this.defaultOptions;

      const customerAdvOptions = this.options.reduce((acc, { appId, attributeId, value }) => {
        if (!acc[appId]) {
          acc[appId] = {};
        }
        acc[appId][attributeId] = value;
        return acc;
      }, {});

      const isStandalone = type === BUNDLE_TYPES.STAND_ALONE;

      return apps.map(({ appId, appName, attributes = [] }) => {
        const options = attributes
            .map(({ id, key, defaultValue, currentValue }) => ({
              key,
              value: customerAdvOptions[appId]?.[id] ?? currentValue ?? defaultValue,
            }))
            .filter(a => a.value && a.value !== 'false')
            .map(({ key, value }) => ({
              text: `${key}${value === 'true' ? '' : `: ${value}`}`,
            }));

        return [
          ...isStandalone || options.length === 0 ? [] : [{ title: `${appName}:` }],
          ...options,
        ];
      }).flat(2);
    }
  },
  methods: {
    toggleList(e) {
      this.isOpen = !this.isOpen;
      e.stopPropagation();
    },
  },
}
</script>

<style scoped>

</style>
